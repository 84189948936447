import SectionHeading from '../../../components/SectionHeading'
import {useColorModeValue} from "@chakra-ui/react";

const TeamBlock = ({ data }) => {
    const colorOpposite = useColorModeValue('#211F24', '#FFEDDE')
    const color = useColorModeValue('#FFEDDE', '#211F24')
    return (
        <section className="our_team_area section-padding-0-0 clearfix" id="team">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionHeading
                            title='Awesome Team'
                            text=''
                        />
                    </div>
                </div>

                <div className="row justify-content-center">
                    {data && data.map((item, key) => (
                        <div className="col-12 col-sm-6 col-lg-3" data-aos="fade-up" key={key}>
                            <div className="single-team-member">
                                <div className="team-member-thumb">
                                    <img src={item.img} className="center-block rounded" alt=""/>
                                </div>
                                <div className="team-info">
                                    <h5 style={{color: colorOpposite}}>{item.title}</h5>
                                    <p style={{color: colorOpposite}}>{item.text}</p>
                                </div>
                                <div className="team-social-icon">
                                    <a href={item.linkedin} target="_blank" rel="noreferrer"  style={{color: "purple"}}><i className="fa fa-linkedin"/> </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default TeamBlock;