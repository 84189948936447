import WelcomeContent from './WelcomeContent'
import {Box, Flex} from "@chakra-ui/react";

const SecWelcomeArea = () => {
    return (
        <section id='home'>
            <WelcomeContent />
        </section>
    );
}

export default SecWelcomeArea;