import {
    AlexisTeam,
    AnonTeam,
    TheotimeTeam,
    AymericTeam,
    RonanJournoudTeam,

    HomeDemo2RoadmapIcons1,
    HomeDemo2RoadmapIcons2,
    HomeDemo2RoadmapIcons3,
    HomeDemo2RoadmapIcons4,
    HomeDemo2RoadmapIcons5,
    HomeDemo2RoadmapIcons6,
    HomeDemo2RoadmapIcons7,
    HomeDemo2RoadmapIcons8,
    HomeDemo2RoadmapIcons9,
} from '../../utils/allImgs'

export const timelineInfo = [
    {
        left:"20.3%",
        ClassName:"data_cir cir_0",
        date:"Janvier, 2022",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:true,
        TextTowLi1:"Élaboration du concept",
        TextTowLi2:"Création de l’équipe",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons1

    },
    {
        left:"33%",
        ClassName:"data_cir cir_1",
        date:"Mai, 2022",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:true,
        TextTowLi1:"Sélection et recrutement des traders",
        TextTowLi2:"Développement de l'API",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        img:HomeDemo2RoadmapIcons2
    },
    {
        left:"46%",
        ClassName:"data_cir cir_2",
        date:"Juillet, 2022",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:'',
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Analyse juridique",
        TextThreeLi2:"Développement de l'API",
        TextThreeLi3:"NFT terminés à 100%",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons3
    },
    {
        left:"59%",
        ClassName:"data_cir cir_3",
        date:"Sept, 2022",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Test des API",
        TextThreeLi2:"NFT terminés à 100%",
        TextThreeLi3:"Lancement du site web + dApp",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons4

    },
    {
        left:"72%",
        ClassName:"data_cir cir_4 red_c",
        date:"Oct-Dec, 2022",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Mint public",
        TextThreeLi2:"Ouverture des abonnements",
        TextThreeLi3:"Connexion entre les abonnés et les traders",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons5
    },
    {
        left:"85%",
        ClassName:"data_cir cir_5 next",
        date:"Mar, 2023",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"Libération de 50 NFT",
        TextTowLi2:"Nouvelles fonctionnalités",
        IsThreeLi:true,
        TextThreeLi1:"Nouvelles fonctionnalités",
        TextThreeLi2:"Migration vers le nouveau broker",
        TextThreeLi3:"Connexion des clients à la nouvelle stratégie",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons6
    },
]

export const timelineInfo2 = [
    {
        timelineClass: "block block-left",
        title: 'Project announcement',
        date_from: 'Jan 15, 2022',
        date_to: 'Jan 15, 2022',
        desc: 'Launch of the web application and announcement of the project to the public.'
    },
    {
        timelineClass: "block block-right mt-30",
        title: 'Whitelist opening',
        date_from: 'Feb 1, 2022',
        date_to: 'Feb 1, 2022',
        desc: 'At that time, we will communicate how to join the whitelist. The members of the whitelist will be able to integrate the project beforehand.'
    },
    {
        timelineClass: "block block-left mt-30",
        title: 'Pre-sales',
        date_from: 'Feb 15, 2022',
        date_to: 'Mar 10, 2022',
        desc: 'At that time, we will make a limited sale of NFTs with benefits that will not be available at the public sale'
    },
    {
        timelineClass: "block block-right mt-30",
        title: 'Public sales & Season 1 starting',
        date_from: 'Mar 15, 2022',
        date_to: 'Mar 15, 2022',
        desc: 'NFTs public sales on our dApp and enjoy the season'
    },
    {
        timelineClass: "block block-left mt-30",
        title: 'Season 1 ending',
        date_from: 'Mar 15, 2022',
        date_to: 'Mai-Aug 15, 2022',
        desc: 'The 1st season will last between 3 to 6 months and will give way to a 2nd season just as exciting'
    }
]

export const faqInfo = [
    {
        text: 'Quelles sont les avantages de détenir un NFT ?',
        answer: 'Ce pass à vie vous permet de profiter du projet tant que vous\n' +
            'le conservez. Si vous le revendez, l’échangez ou le perdez, vos\n' +
            'avantages seront relayés au nouvel acquéreur.\n' +
            'Plus vous achetez de NFT, plus vous avez de chance d’obtenir un\n' +
            'NFT ULTRA RARE ! Au fil du temps, WAGTI. offrira des avantages aux\n' +
            'holders possédants plusieurs NFT…',
        id: 'Q1'
    },
    {
        text: 'Combien de NFT seront disponible et à quel prix ?',
        answer: 'Nous avons fait le choix de\n' +
            'créer une collection de 600 NFT\n' +
            'au prix unitaire de 550$.',
        id: 'Q2'
    },
    {
        text: 'J\'aimerai gagner un NFT, comment faire?',
        answer: 'Suis nous sur les réseaux sociaux, actives les notifications et soit prêt, des NFT seront à gagner mais aussi des WL et des API! SPOILER!! Celui qui invite le + de monde sur le serveur aura un NFT offert!',
        id: 'Q3'
    },
    {
        text: 'Je suis trader, spécialiste web3, j\'ai un talent qui peut compléter les services de votre entreprise... Où puis-je vous contacter?',
        answer: "WAGTI. est toujours ouvert aux opportunités et à la discussion. Si tu penses pouvoir apporter ta pierre à l'édifice, contactes-nous directement par message privé discord. Sinon contactes-nous sur nos réseaux sociaux où nos LinkedIn respectifs.",
        id: 'Q4'
    },
]

export const DocElementTitle = [
    {
        title: 'WhitePaper'
    },
    {
        title: 'OnePager'
    },
    {
        title: 'Rating Review'
    },
    {
        title: 'Marketing Plan'
    },
]

export const TeamMember = [
    {
        img: AlexisTeam,
        title: 'Alexis Piles',
        text: 'Fondateur, président de WAGTI Corp',
        linkedin: 'https://www.linkedin.com/in/piles-alexis-3a2813214/'
    },
    {
        img: AymericTeam,
        title: 'Aymeric Lorenzelli',
        text: 'Co-fondateur, directeur technique',
        linkedin: 'https://www.linkedin.com/in/aymeric-lorenzelli-4b7b45162/'
    },
    {
        img: TheotimeTeam,
        title: 'Théotime Bibollet',
        text: 'Co-fondateur, directeur marketing',
        linkedin: 'https://www.linkedin.com/in/th%C3%A9otime-bibollet-b93237205/'
    },
    {
        img: RonanJournoudTeam,
        title: 'Ronan Journoud',
        text: ' Avocat spécialisé dans les crypto-actifs',
        linkedin: 'https://www.linkedin.com/in/ronan-journoud-a1214661/'
    },
    {
        img: AnonTeam,
        title: 'Sumo',
        text: 'Développeur',
    },
    {
        img: AnonTeam,
        title: 'Mathieu',
        text: 'Développeur',
    },
]

export const PartnersData = [
    {
        img: 'Ynov',
        link: 'https://www.ynov.com'
    }
]
