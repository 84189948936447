import SectionHeading from '../../../components/SectionHeading'
import {FaEthereum, FaBitcoin, FaMonero } from "react-icons/fa";
import { SiDogecoin } from "react-icons/si";
import { TbAtom } from "react-icons/tb";
import {Box, Container, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from "@chakra-ui/react";

const CollectionBlock = () => {
    return (
        <section className="trust-section section-padding-100" style={{display: 'none'}}>
        <Flex justifyContent={"center"} display={{base: 'none', md:'flex'}}>
                    <Flex justifyContent={"center"} maxW={'70%'}>
                        <Tabs>
                            <TabList flexDirection={{base: 'column', sm:'row', md: 'row', lg: 'row'}} color={'purple'} _active={{ color: 'purple'}} _focus={{color: 'purple'}}>
                                <Tab>Bitcoin</Tab>
                                <Tab>Etherum</Tab>
                                <Tab>Atom</Tab>
                                <Tab>Dogecoin</Tab>
                                <Tab>Monero</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel pt={'4rem'}>
                                    <Flex justifyContent={"center"} h={'10rem'}>
                                        <Flex position={"absolute"}>
                                            <div className="icon-box">
                                                <span><FaBitcoin size={50} style={{top: '24px', right: '-25px', position: 'absolute'}}/></span>
                                            </div>
                                        </Flex>
                                    </Flex>
                                    <Flex flexDirection={"column"} alignItems={"center"}>
                                        <Flex>
                                            <Heading as={'h2'} fontSize={'20px'} mb={3}>Bitcoin</Heading>
                                        </Flex>
                                        <Flex>
                                            <Text textAlign={"center"}>Le robot WAGTI., incarne BTC, toujours aussi incontesté et incontestable. Attention aux
                                                market makers !
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </TabPanel>
                                <TabPanel pt={'4rem'}>
                                    <Flex justifyContent={"center"} h={'10rem'}>
                                        <Flex position={"absolute"}>
                                            <div className="icon-box">
                                                <span><FaEthereum size={50} style={{top: '24px', right: '-25px', position: 'absolute'}}/></span>
                                            </div>
                                        </Flex>
                                    </Flex>
                                    <Flex flexDirection={"column"} alignItems={"center"}>
                                        <Flex>
                                            <Heading as={'h2'} fontSize={'20px'} mb={3}>ETH</Heading>
                                        </Flex>
                                        <Flex maxW={'50%'}>
                                            <Text textAlign={"center"}>Le robot WAGTI, incarne l’ETHEREUM et ses évolutions traversant les différentes mises à
                                                jour majeures tout au long de son évolution.
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </TabPanel>
                                <TabPanel pt={'4rem'}>
                                    <Flex justifyContent={"center"} h={'10rem'}>
                                        <Flex position={"absolute"}>
                                            <div className="icon-box">
                                                <span><TbAtom size={50} style={{top: '24px', right: '-25px', position: 'absolute'}}/></span>
                                            </div>
                                        </Flex>
                                    </Flex>
                                    <Flex flexDirection={"column"} alignItems={"center"}>
                                        <Flex>
                                            <Heading as={'h2'} fontSize={'20px'} mb={3}>Atom</Heading>
                                        </Flex>
                                        <Flex>
                                            <Text textAlign={"center"}>Le robot WAGTI., incarne ATOM au sein de l’écosystème cosmos et son interopérabilité.
                                                Il profite de chacune des spécificités disponibles en son sein comme l’hyperfluid staking
                                                de OSMOSIS ou bien la privacy de SECRET.

                                            </Text>
                                        </Flex>
                                    </Flex>
                                </TabPanel>
                                <TabPanel pt={'4rem'}>
                                    <Flex justifyContent={"center"} h={'10rem'}>
                                        <Flex position={"absolute"}>
                                            <div className="icon-box">
                                                <span><SiDogecoin size={50} style={{top: '24px', right: '-25px', position: 'absolute'}}/></span>
                                            </div>
                                        </Flex>
                                    </Flex>
                                    <Flex flexDirection={"column"} alignItems={"center"}>
                                        <Flex>
                                            <Heading as={'h2'} fontSize={'20px'} mb={3}>DogeCoin</Heading>
                                        </Flex>
                                        <Flex>
                                            <Text textAlign={"center"}>Le robot WAGTI., incarne DOGE et sa résilience envers les attaques qui lui sont portées.
                                                Entre haters et Tech, qui réussira à le faire vaciller ?
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </TabPanel>
                                <TabPanel pt={'4rem'}>
                                    <Flex justifyContent={"center"} h={'10rem'}>
                                        <Flex position={"absolute"}>
                                            <div className="icon-box" data-aos='fade-up' data-aos-delay='1000'>
                                                <span><FaMonero size={50} style={{top: '24px', right: '-25px', position: 'absolute'}}/></span>
                                            </div>
                                        </Flex>
                                    </Flex>
                                    <Flex flexDirection={"column"} alignItems={"center"}>
                                        <Flex>
                                            <Heading as={'h2'} fontSize={'20px'} mb={3}>Monero</Heading>
                                        </Flex>
                                        <Flex>
                                            <Text textAlign={"center"}>Le robot WAGTI., incarne MONERO et son aspect mystérieux et intraçable.
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Flex>
        </Flex>
        </section>
    );
}

export default CollectionBlock;