import { Flex, Heading, Text } from "@chakra-ui/react";
import React, { Fragment } from "react";

const SectionHeading = ({ title, text }) => {
    return (
        <Flex justifyContent={"center"} flexDirection={"column"} alignItems={"center"} m={"2em"} mb={"4em"}>
            <div className="dream-dots justify-content-center" data-aos="fade-up" data-aos-delay='200'>
                {Array(7).fill().map((item, key) => (
                    <span key={key}/>
                ))}
            </div>
            <Heading as={"h2"} mb={5} data-aos="fade-up" data-aos-delay='300'>{title}</Heading>
            <Text data-aos="fade-up" data-aos-delay='400' textAlign={"center"}>
                {text.split('\n').map((line, index) => (
                    <Fragment key={index}>
                        {line}
                        <br />
                    </Fragment>
                ))}
            </Text>
        </Flex>
    );
}

export default SectionHeading;