import {
  Box,
  Flex,
  Heading,
  Link,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { TypeAnimation } from "react-type-animation";
import CountdownTimer from "../../../../components/CountDown/CountdownTimer";

const WelcomeContent = () => {
  const colorOpposite = useColorModeValue("#211F24", "#FFEDDE");
  const countDownDate = new Date("March 1, 2023 21:00:00").getTime();
  const now = new Date().getTime();
  const additionalTime = countDownDate - now;
  const finalDate = now + additionalTime;

  return (
    <Box
      className="welcome-content"
      top={{ base: "10rem", sm: "10rem", md: "10rem", lg: "10rem" }}
    >
      <Flex
        justifyContent={"center"}
        textAlign={"center"}
        fontSize={{ base: "2rem", sm: "2rem", md: "3rem" }}
        m={{ base: "1rem", sm: "1rem", md: "1rem" }}
        minH={{ base: "5rem", sm: "5rem", md: "none" }}
      >
        <Heading as="h1" textAlign="center" fontSize={{ base: "xl", md: "2xl" }}>
        WAGTI: Apprenez, échangez, grandissez
        </Heading>
      </Flex>
      
      <VStack spacing={6} align="stretch" px={{ base: 4, md: 10 }} mt={10}>
        <Heading as="h3" textAlign="center" fontSize={{ base: "xl", md: "2xl" }}>
          Nos Services
        </Heading>
        <UnorderedList spacing={4} styleType="none" textAlign={"center"}>
          <ListItem>
            <Text fontSize={{ base: "md", md: "lg" }}>
              🚀 Un groupe Discord pour apprendre, échanger et grandir ensemble dans le monde des investissements.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize={{ base: "md", md: "lg" }}>
              📰 Une newsletter hebdomadaire avec plus de 220 articles sur l'actualité blockchain et l'analyse de projets.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize={{ base: "md", md: "lg" }}>
              🤝 Un accès à un réseau d'investisseurs et d'entrepreneurs complet et compétent.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize={{ base: "md", md: "lg" }}>
              💎 Des opportunités d'investissement uniques.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize={{ base: "md", md: "lg" }}>
              🎓 Un accompagnement personnalisé pour vos investissements et un apprentissage guidé.
            </Text>
          </ListItem>
        </UnorderedList>
      </VStack>

      <Flex my={"1rem"} justifyContent={"center"}>
        <CountdownTimer targetDate={finalDate} />
      </Flex>

      <Box
        my={"4rem"}
        className="dream-btn-group d-flex justify-content-center"
      >
        <Link
          href="https://discord.gg/J2ZUxfjjyr"
          target="_blank"
          className="btnn btn-1"
        >
          <svg>
            <rect
              x="0"
              y="0"
              fill="none"
              stroke={colorOpposite}
              width="100%"
              height="100%"
            />
          </svg>
          Rejoignez-nous
        </Link>
      </Box>
    </Box>
  );
};

export default WelcomeContent;