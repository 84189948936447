import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Aos from 'aos'
import themeLight from './theme/theme'
import { ChakraProvider } from '@chakra-ui/react'

import HomePage from './template/HomePage'

import 'aos/dist/aos.css';
import './assets/css/General.css';
import './assets/css/bootstrap.min.css';
import './assets/css/responsive.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';

const App = () => {
    useEffect(() => {
        Aos.init({
            duration: 1000
        });
    }, [])

    return (
        <ChakraProvider theme={themeLight}>
            <div className="App">
                <Switch>
                    <Route path="/" exact component={HomePage} />
                </Switch>
            </div>
        </ChakraProvider>
    );
}

export default App;