import {Cgv, Confidentiality} from "../../../utils/allImgs";

const SecContent = () => {
	return(
        <div className="col-12 col-md-7">
          {/* Content Info */}
          <div className="contact_info_area d-sm-flex justify-content-start">
            <div className="contact_info text-center wow fadeInUp mr-5" data-wow-delay="0.2s">
              <h5>NAVIGATE</h5>
              <a href="#home"><p>Home</p></a>
              <a href="#nft"><p>NFT's</p></a>
              <a href="#roadmap"><p>Roadmap</p></a>
            </div>
            <div className="contact_info text-center wow fadeInUp mr-5 mt-4" data-wow-delay="0.2s">
              <a href="#faq"><p>FAQ</p></a>
              <a href="#team"><p>Team</p></a>
              {/*<a href="#partner"><p>Partner</p></a>*/}
            </div>
            {/* Content Info */}
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.3s">
              <h5>PRIVACY</h5>
              <a href={Cgv} target={"_blank"}><p>CGV</p></a>
              <a href={Confidentiality} target={"_blank"}><p>Politique de confidentialité</p></a>
            </div>
            {/* Content Info
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.4s">
              <h5>Phone</h5>
              <p>Mailing Address:xx00 E. Union Ave</p>
              <p>Suite 1100. Denver, CO 80237</p>
              <p>+999 90932 627</p>
              <p>support@yourdomain.com</p>
            </div>*/}
          </div>
        </div>
	)
}

export default SecContent