import './style/HomeDemo1.scss'

import {
    timelineInfo,
    faqInfo,
    TeamMember,
    PartnersData
} from '../../data/data-containers/data-HomePage.js';

import {
    HomeDemo2BgRoadmap
} from '../../utils/allImgs'

import Header from '../../layouts/Header'
import Footer from '../../layouts/FooterPages'

import WelcomeBlock from './WelcomeBlock'
import NftBlock from './NftBlock'
import SecOurRoadmap from './SecOurRoadmap2'
import CollectionBlock from './CollectionBlock'
import FAQBlock from './FAQBlock'
import TeamBlock from './TeamBlock'
import PartnersBlock from './PartnersBlock'

const HomePage = () => {
    return (
        <>
            <Header/>
            <div className="HomeDemo1">
                <WelcomeBlock />
                <NftBlock />
                <CollectionBlock/>

                <div className="clearfix"/>

                <SecOurRoadmap data={timelineInfo} img={HomeDemo2BgRoadmap} />
                {/*<Roadmap data={timelineInfo} />*/}
                <FAQBlock faqInfo={faqInfo} />
                <TeamBlock data={TeamMember} />
                {/*<PartnersBlock data={PartnersData} />*/}
            </div>
            <Footer/>
        </>
    );
};

export default HomePage;