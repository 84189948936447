import {NavbarDarkLogo} from '../../utils/allImgs'
import {NavbarLightLogo} from '../../utils/allImgs'

export const LogoLight = NavbarLightLogo;
export const LogoDark = NavbarDarkLogo;

export const MenuInfo = [
	{
		path:"#home",
		nameLink:'Home'
	},
	{
		path:"#nft",
		nameLink:'NFT'
	},
	{
		path:"#roadmap",
		nameLink:'Roadmap'
	},
	{
		path:"#collection",
		nameLink:'Collection'
	},
	{
		path:"#ama",
		nameLink:'AMA'
	},
	{
		path:"#team",
		nameLink:'Team'
	},
	{
		path:"#partners",
		nameLink:'Partners'
	}
]