// theme.js
import { extendTheme } from '@chakra-ui/react'
import {ComponentStyleConfig} from "@chakra-ui/react";


const themeLight = extendTheme({
    fonts: {
            heading: `'Poppins', sans-serif`,
    },
    shadows: {
        header: '0 2px 13px rgb(0 0 0 / 36%);'
    },
    colors: {
        purple: '#9469BF'
    },
    styles: {
        global: (props) => ({
            'html, body': {
                fontSize: 'sm',
                fontFamily: `'Poppins', sans-serif`,
                color: props.colorMode === 'dark' ? '#FFEDDE' : '#211F24',
                lineHeight: 'tall',
                bg: props.colorMode === 'dark' ? '#211F24' : '#FFEDDE'
            },
            'span': {
                color: props.colorMode === 'dark' ? '#FFEDDE' : '#211F24',
            },
            a: {
                color: props.colorMode === 'dark' ? '#FFEDDE' : '#211F24',
                _hover: {
                    bg: props.colorMode === 'dark' ? '#211F24' : '#FFEDDE',
                    color: props.colorMode === 'dark' ? '#FFEDDE' : '#211F24',
                }
            },
            h2: {
                color: props.colorMode === 'dark' ? '#FFEDDE' : '#211F24',
            },
            header: {
                bg: props.colorMode === 'dark' ? '#211F24' : '#FFEDDE',
                a: {
                    color: props.colorMode === 'dark' ? '#FFEDDE' : '#211F24',
                    _hover: {
                        bg: props.colorMode === 'dark' ? '#211F24' : '#FFEDDE',
                        color: props.colorMode === 'dark' ? '#FFEDDE' : '#211F24',
                    }
                },

            },
            footer: {
                bg: props.colorMode === 'dark' ? '#211F24' : '#FFEDDE',
                a: {
                    color: props.colorMode === 'dark' ? '#211F24' : '#FFEDDE',
                    _hover: {
                        color:  props.colorMode === 'dark' ? '#211F24' : '#FFEDDE',
                    }
                },
            },
            '.nft-slider video': {
                objectFit: 'contain'
            }
        }),
    },
})

export default themeLight