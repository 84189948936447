import React from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "./Countdown";
import { Flex } from "@chakra-ui/react";

const ExpiredNotice = () => {
  return <div className="expired-notice"></div>;
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <Flex
      justifyContent={"center"}
      flexDirection={{ base: "column", md: "row" }}
    >
      <DateTimeDisplay value={days} type={"Jours"} isDanger={days <= 3} />
      <DateTimeDisplay value={hours} type={"Heures"} isDanger={false} />
      <DateTimeDisplay value={minutes} type={"Minutes"} isDanger={false} />
      <DateTimeDisplay value={seconds} type={"Secondes"} isDanger={false} />
    </Flex>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
