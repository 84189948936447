import React from 'react';
import {Text} from "@chakra-ui/react";

const DateTimeDisplay = ({ value, type, isDanger }) => {
    return (
        <div className="clock-col">
            <Text fontSize={{base: '2rem', md: '4.2rem'}} className="clock-minutes clock-timer">
                {value}
            </Text>
            <p className="clock-label">
                {type}
            </p>
        </div>
    );
};

export default DateTimeDisplay;