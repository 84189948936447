import {Flex, Image, Link, useColorModeValue} from "@chakra-ui/react";
import {FaDiscord, FaInstagram, FaLinkedin, FaMedium, FaTwitter} from "react-icons/fa";

const SecIco = ({logo}) => {
    const colorOpposite = useColorModeValue('#211F24', '#FFEDDE')
    const color = useColorModeValue('#FFEDDE', '#211F24')
    return (
        <div className="col-12 col-md-5">
            <div className="footer-copywrite-info">
                {/* Copywrite */}
                <div className="copywrite_text wow fadeInUp" data-wow-delay="0.2s">
                    <div className="footer-logo">
                        <a href="#"><Image src={logo} alt="logo" w={'5em'}/></a>
                    </div>
                    <p>Copyright 2022 par WAGTI. Tous droits réservés.</p>
                </div>
                {/* Social Icon */}
                <Flex>
                    <Link href="https://www.instagram.com/wagti.corp/" target="_blank" pr={2} color={color} _hover={{bg: 'none', color: color}}><FaInstagram size={20}></FaInstagram></Link>
                    <Link href="https://www.linkedin.com/company/w-a-g-t-i-corp/" target="_blank" px={2} color={color} _hover={{bg: 'none', color: color}}><FaLinkedin size={20}></FaLinkedin></Link>
                    <Link href="https://discord.gg/wagticorp" target="_blank" px={2} color={color} _hover={{bg: 'none', color: color}}><FaDiscord size={20}></FaDiscord></Link>
                    <Link href="https://twitter.com/wagticorp" target="_blank" px={2} color={color} _hover={{bg: 'none', color: color}}><FaTwitter size={20}></FaTwitter></Link>
                    <Link href="https://d1fdloi71mui9q.cloudfront.net/xUyzARIbR8CoubxbaoKx_Medium_Wagti_Officiel_2.pdf" target="_blank" px={2} color={color} _hover={{bg: 'none', color: color}}><FaMedium size={20}></FaMedium></Link>
                </Flex>
            </div>
        </div>
    )
}

export default SecIco