import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure, Image, Fade, useColorMode,
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon, ChatIcon, SunIcon, MoonIcon,
} from '@chakra-ui/icons';
import {LogoLight} from "../../data/data-layout/data-Header";
import {LogoDark} from "../../data/data-layout/data-Header";
import {useEffect, useState} from "react";
import {FaDiscord, FaInstagram, FaLinkedin, FaMedium, FaTwitter} from "react-icons/fa";
import {MdLightMode, MdNightlight} from "react-icons/md";

export default function Header() {
    const { isOpen, onToggle } = useDisclosure();
    const [position, setPosition] = useState(window.scrollY)
    const [visible, setVisible] = useState(true)
    const { colorMode, toggleColorMode } = useColorMode()
    const colorOpposite = useColorModeValue('#211F24', '#FFEDDE')
    const color = useColorModeValue('#FFEDDE', '#211F24')
    useEffect(()=> {
        const handleScroll = () => {
            let moving = window.scrollY
            if(position > 100){
                setVisible(position > moving);
            }
            setPosition(moving)
        };
        window.addEventListener("scroll", handleScroll);
        return(() => {
            window.removeEventListener("scroll", handleScroll);
        })
    })

    const opacity = visible ? 1 : 0;

    return (
        <Box as="header" position="fixed" w="100%" mb={3} zIndex={200} shadow={"header"} opacity={opacity} style={{transition:'visibility 0.8s linear,opacity 0.8s linear, top 0.5s linear'}} top={opacity === 0 ? -400: 0} opacity={0.9} left={0}>
            <Flex flexDir={'column'}>
                <Flex
                    minH={'60px'}
                    px={3}
                    align={'center'} >
                    <Flex
                        flex={{ base: 1 ,md: 'auto' }}
                        display={{ base: 'flex', md: 'none' }}>
                        <IconButton
                            onClick={onToggle}
                            icon={
                                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                            }
                            variant={'ghost'}
                            aria-label={'Toggle Navigation'}
                        />
                        <Flex alignItems={"center"}  justifyContent={"center"}>
                            <IconButton
                                onClick={toggleColorMode}
                                icon={
                                    colorMode === 'dark' ? <MdLightMode size={25}/> : <MdNightlight size={25}/>
                                }
                                bg={"none"}
                                _hover={"none"}
                                border={"none"}
                                _focus={"none"}
                                aria-label={'Toggle Navigation'}
                            />
                        </Flex>
                    </Flex>
                    <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'space-around' }}>
                        <Flex display={{ base: 'none', md: 'flex' }} flexFlow={"row wrap"}  justifyContent={"center"} alignItems={"center"} flexGrow={0.5}>
                            <Flex>
                                <Link href="https://www.instagram.com/wagti.corp/" target="_blank" px={2}><FaInstagram size={20}></FaInstagram></Link>
                                <Link href="https://www.linkedin.com/company/w-a-g-t-i-corp/" target="_blank" px={2}><FaLinkedin size={20}></FaLinkedin></Link>
                                <Link href="https://discord.gg/wagticorp" target="_blank" px={2}><FaDiscord size={20}></FaDiscord></Link>
                                <Link href="https://twitter.com/wagticorp" target="_blank" px={2}><FaTwitter size={20}></FaTwitter></Link>
                                <Link href="https://d1fdloi71mui9q.cloudfront.net/xUyzARIbR8CoubxbaoKx_Medium_Wagti_Officiel_2.pdf" target="_blank" px={2}><FaMedium size={20}></FaMedium></Link>
                            </Flex>
                        </Flex>
                        <Flex flexFlow={{ base: 'none', md: "row wrap" }} justifyContent={{base: "end", sm: "end", md: "center", lg: "center"}} flexGrow={1}>
                            <Link href="/#home"><Image src={useColorModeValue(LogoDark, LogoLight)} w={"11em"} my={"3rem"}></Image></Link>
                        </Flex>
                        <Flex display={{ base: 'none', md: 'flex' }} alignItems={"center"} flexFlow={{ base: 'none', md: "row wrap" }}  flexGrow={1} justifyContent={"center"}>
                            <DesktopNav />
                        </Flex>
                        <Flex display={{ base: 'none', md: 'flex' }} alignItems={"center"} flexFlow={{ base: 'none', md: "row wrap" }}  justifyContent={"start"} flexGrow={0.5} >
                            <IconButton
                                onClick={toggleColorMode}
                                icon={
                                    colorMode === 'dark' ? <MdLightMode size={25}/> : <MdNightlight size={25}/>
                                }
                                bg={"none"}
                                _focus={{outline: 'none'}}
                                aria-label={'Toggle Navigation'}
                            />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex display={{ base: 'flex', md: 'none' }} >
                    <Flex w={'50%'}></Flex>
                    <Flex w={'50%'} justifyContent={"space-evenly"} pb={4}>
                        <Link href="https://www.instagram.com/wagti.corp/" target="_blank"><FaInstagram size={20}></FaInstagram></Link>
                        <Link href="https://www.linkedin.com/company/w-a-g-t-i-corp/" target="_blank"><FaLinkedin size={20}></FaLinkedin></Link>
                        <Link href=""><FaDiscord size={20}></FaDiscord></Link>
                        <Link href="https://twitter.com/wagticorp" target="_blank"><FaTwitter size={20}></FaTwitter></Link>
                        <Link href="https://d1fdloi71mui9q.cloudfront.net/xUyzARIbR8CoubxbaoKx_Medium_Wagti_Officiel_2.pdf" target="_blank"><FaMedium size={20}></FaMedium></Link>
                    </Flex>
                </Flex>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
}

const DesktopNav = () => {
    const colorOpposite = useColorModeValue('#211F24', '#FFEDDE')
    const color = useColorModeValue('#FFEDDE', '#211F24')

    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Link
                        p={2}
                        href={navItem.href ?? '#'}
                        fontSize={'sm'}
                        fontWeight={700}
                        color={colorOpposite}
                        _hover={{
                            textDecoration: 'none',
                            color: {colorOpposite}
                        }}>
                        {navItem.label}
                    </Link>
                </Box>
            ))}
        </Stack>
    );
};

const MobileNav = () => {
    const colorOpposite = useColorModeValue('#211F24', '#FFEDDE')
    const color = useColorModeValue('#FFEDDE', '#211F24')
    return (
        <Stack
            bg={color}
            p={4}
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

const NAV_ITEMS = [
    {
        label: 'HOME',
        href: '#home',
    },
    {
        label: 'NFT',
        href: '#nft',
    },
    {
        label: 'ROADMAP',
        href: '#roadmap',
    },
    {
        label: 'TEAM',
        href: '#team',
    },
];

const SocialMedia = [
    {
        label: '<FaInstagram>',
        href: '#home',
    },
    {
        label: 'Discord',
        href: '#nft',
    },
    {
        label: 'Twitter',
        href: '#roadmap',
    },
    {
        label: 'Medium',
        href: '#team',
    },
    {
        label: 'Medium',
        href: '#team',
    },
];