import SectionHeading from '../../../components/SectionHeading'
import FAQItem from './FAQItem'

const FAQBlock = ({ faqInfo }) => {
    return (
        <section className="faq-timeline-area section-padding-100" id="ama">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12 mb-5">
                        <SectionHeading
                            title='FAQ'
                            text='Trouvez ci-dessous la réponse à toutes vos questions sur notre collection NFT.'
                        />

                        <div className="dream-faq-area">
                            <div className="panel-group" id="accordionFourLeft" data-aos="fade-up">
                                {faqInfo && faqInfo.map((item, key) => (
                                    <FAQItem key={key} text={item.text} id={item.id} answer={item.answer}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FAQBlock;