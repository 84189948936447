import {Box, Text, useColorModeValue} from "@chakra-ui/react";

const FAQItem = ({text, answer, id}) => {
    const colorOpposite = useColorModeValue('#211F24', '#FFEDDE')
    const color = useColorModeValue('#FFEDDE', '#211F24')
    return (
        <>
            <Box fontSize={'xl'} className="test1" data-toggle="collapse" aria-expanded="false" aria-controls={id} data-target={`#${id}`} color={colorOpposite}>{text}</Box>
            <div id={id} className="collapse p-0">
                <Text py={5} pl={'15px'}>{answer}</Text>
            </div>
        </>
    );
}

export default FAQItem;