import SectionHeading from '../../../components/SectionHeading'
import {Flex} from "@chakra-ui/react";
import AwesomeSlider from "react-awesome-slider";
import AwsSliderStyles from 'react-awesome-slider/dist/styles.css';
import {AtomNFT, BtcNFT, DogeNFT, EthNFT, MoneroNFT} from "../../../utils/allImgs";

const NftBlock = ({ data }) => {
    const text = 'La collection est représentée par cinq familles, chacune rendant hommage à un projet.\nDétenir un de nos NFT vous donne accès à tous nos services.\nSeulement 600 places sont disponibles !';
    return (
        <section className="trust-section section-padding-100" id="nft">

            <SectionHeading
                title='Notre collection de NFT'
                text={text}

            />
            <Flex mx={{base: '10%', sm: '20%', md: '25%'}}>
                <AwesomeSlider
                    className="nft-slider"
                    media={[
                    {
                        source: BtcNFT,
                    },
                    {
                        source: EthNFT,
                    },
                    {
                        source: AtomNFT,
                    },
                    {
                        source: DogeNFT,
                    },
                    {
                        source: MoneroNFT,
                    }
                ]}/>
            </Flex>
        </section>
    );
}

export default NftBlock;