import{
  FooterPattern,
  FooterBg1
} from '../../utils/allImgs'

import './footer.css'

import SecIco from './SecIco'
import SecContent from './SecContent'
import {Heading, useColorModeValue} from "@chakra-ui/react";
import {LogoDark, LogoLight} from "../../data/data-layout/data-Header";

const Footer = () => {
    const colorOpposite = useColorModeValue('#211F24', '#FFEDDE')
    const color = useColorModeValue('#FFEDDE', '#211F24')
    return (
      <Heading as={'footer'} bg={colorOpposite}>
        <div className="footer-content-area">
          <div className="container">
            <div className="row align-items-end">
              <SecIco logo={useColorModeValue(LogoLight, LogoDark)}/>
              <SecContent />
            </div>
          </div>
        </div>
      </Heading>
    );
};

export default Footer